import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=2041f894"
import script from "./Snackbar.vue?vue&type=script&lang=js"
export * from "./Snackbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../nix/store/fs1wq3d45vz7214ira5rffkmydkircpj-cockpit-0.1.0/libexec/cockpit/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports