/*

asyncComputed: {
  value() {
    return Promise.resolve("Hello, World!");
  }
}

asyncComputed: {
  value: {
    handler() {
      return Promise.resolve("Hello, World!");
    },
    default: [],
    resetWhileLoading: true,
    resetWhenChanged() {
      return $store.state.firm.id;
    },
  }
}

*/

const OPTIONNAME = 'asyncComputed';

let currentRefresh;

export default {
    data() {
        return getAsyncComputedProperties(this).reduce(
            (o, [name, spec]) => ({
                ...o,
                [name]: spec.default,
                [loadingName(name)]: false,
                [versionName(name)]: 0,
            }),
            {}
        );
    },
    created() {
        getAsyncComputedProperties(this).forEach(([name, spec]) => {
            let lastPromise = null;
            let resetOnChange = null;
            const refresh = () => {
                this[versionName(name)]++;
            };
            refresh.onCleanup = f => {
                this.$on('hook:beforeDestroy', () => {
                    f();
                });
            };
            const handler = spec.handler.bind(this);
            this.$watch(
                () => {
                    this[versionName(name)];
                    try {
                        currentRefresh = refresh;
                        return handler();
                    } finally {
                        currentRefresh = undefined;
                    }
                },
                promise => {
                    if (!(promise instanceof Promise)) {
                        throw new Error(
                            `Watched property ${name} for asyncComputed must return Promise got ${typeof promise}`
                        );
                    }
                    this[loadingName(name)] = true;
                    const changeValue = spec.resetWhenChanged.call(this);
                    if (
                        spec.resetWhileLoading ||
                        resetOnChange !== changeValue
                    ) {
                        this[name] = spec.default;
                    }
                    lastPromise = promise;
                    resetOnChange = changeValue;
                    lastPromise
                        .then(v => {
                            if (lastPromise === promise) {
                                this[name] = v;
                            }
                        })
                        .finally(() => {
                            if (lastPromise === promise) {
                                this[loadingName(name)] = false;
                            }
                        });
                },
                { immediate: true }
            );
        });
    },
};

export function getCurrentRefresh() {
    return currentRefresh;
}

function getAsyncComputedProperties(vm) {
    return Object.entries(vm.$options[OPTIONNAME] || {}).map(([name, spec]) => {
        if (typeof spec === 'function') {
            spec = { handler: spec };
        }
        return [
            name,
            {
                default: [],
                resetWhileLoading: true,
                resetWhenChanged: () => null,
                ...spec,
            },
        ];
    });
}

function loadingName(name) {
    return name + 'Loading';
}

function versionName(name) {
    return name + 'Version';
}
