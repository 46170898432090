import moment from 'moment';
import { unauthorizedRedirect } from './unauthorizedRedirect';
import {
    ApolloError,
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client/core';
import { store } from './store/store';
import Period from './period';
import retryingFetch from './retryingFetch';
import dataUriToBlob from './helpers/dataUriToBlob';
import { __, language } from './i18n';

export const userClient = makeClient('user', queryUri => {
    queryUri.searchParams.set('firm_id', store.state.firm.id);
    return queryUri;
});

export const employeeClient = makeClient('employee', queryUri => queryUri);
export const publicClient = makeClient('public', queryUri => queryUri);
export const kioskClient = makeClient('kiosk', queryUri => queryUri);

function makeClient(urlPrefix, rewriteUri) {
    const uri = new URL(process.env.VUE_APP_API_URL, location.href);
    const schemaUri = `${uri}/${urlPrefix}/schema`;
    const queryUri = `${uri}/${urlPrefix}/query`;

    return cacheAsync(async function () {
        const httpLink = new HttpLink({
            fetch: retryingFetch,
        });

        const firmIdMiddleware = new ApolloLink((operation, forward) => {
            operation.setContext(() => ({
                uri: rewriteUri(new URL(queryUri)).toString(),
                credentials: 'same-origin',
            }));
            return forward(operation);
        });

        const languageMiddleware = new ApolloLink((operation, forward) => {
            operation.setContext(() => ({
                headers: {
                    'Accept-Language': language.value,
                },
            }));
            return forward(operation);
        });

        const snackbarAfterware = new ApolloLink((operation, forward) => {
            return forward(operation).map(response => {
                for (const snackbar of Object.values(response.data).filter(
                    obj => obj?.__typename === 'SnackbarType'
                )) {
                    store.commit('snackbar/addSnackbar', snackbar);
                }
                return response;
            });
        });

        const typePolicies = await getTypePolicies(schemaUri);

        const link = firmIdMiddleware
            .concat(languageMiddleware)
            .concat(snackbarAfterware)
            .concat(httpLink);

        return () => {
            const client = new ApolloClient({
                link,
                cache: new InMemoryCache({
                    typePolicies,
                }),
                assumeImmutableResults: true,
                defaultOptions: {
                    query: {
                        fetchPolicy: 'network-only',
                    },
                    // Without this, type policies are not applied to mutation results.
                    // See: https://github.com/apollographql/apollo-client/issues/10081
                    mutate: {
                        update: () => {},
                    },
                },
            });

            client.__clientType = urlPrefix;

            return client;
        };
    });
}

function cacheAsync(fn) {
    let chain = Promise.resolve();

    return () => {
        const promise = chain.then(result =>
            result === undefined ? fn() : result
        );

        chain = promise.catch(e => {
            console.error(e);
        });

        return promise.then(f => f());
    };
}

const isApolloError = Symbol('isApolloError');

export async function executeQuery(clientMaker, query) {
    const client = await clientMaker();
    const callee = getClientCallee(client, query);
    let data;
    try {
        data = (await callee(query)).data;
    } catch (e) {
        if (e.networkError?.statusCode === 401) {
            unauthorizedRedirect(client.__clientType === 'employee');
        }
        e[isApolloError] = true;
        throw e;
    }
    if (Object.keys(data).length !== 1) {
        throw new Error('Expected exactly one key in GraphQL query result');
    }
    return data[Object.keys(data)[0]];
}

window.addEventListener('unhandledrejection', e => {
    if (!e.reason[isApolloError]) {
        return;
    }
    store.commit('errorHandling/addMessage', getErrorMessage(e.reason));
});

export function getErrorMessage(e) {
    if (e.networkError?.statusCode === 401) {
        return __('Your session expired');
    }

    if (
        e instanceof ApolloError &&
        e.networkError?.result?.errors?.length > 0
    ) {
        return (
            'Server error: ' +
            e.networkError.result.errors.map(e => e.message).join(';')
        );
    }

    return e.message;
}

function getClientCallee(client, query) {
    if ('query' in query && !('mutation' in query)) {
        return client.query;
    } else if ('mutation' in query && !('query' in query)) {
        return client.mutate;
    } else {
        throw new Error(
            "GraphQL query should contain either 'query' or 'mutation'"
        );
    }
}

const readDateTime = decodeProxy(decodeDateTime);
const readTimeDelta = decodeProxy(decodeDuration);
const readTime = decodeProxy(decodeTime);

function decodeDateTime(v) {
    const result = moment(v, 'YYYY-MM-DD[T]HH:mm:ss');
    if (!result.isValid()) {
        console.error('Got invalid datetime from backend:', v);
    }
    return result;
}

function decodeDuration(v) {
    const result = moment.duration(v);
    if (!result.isValid()) {
        console.error('Got invalid duration from backend:', v);
    }
    return result;
}

function decodeTime(v) {
    const result = moment(`1970-01-01T${v}`, 'YYYY-MM-DD[T]HH:mm:ss.S');
    if (!result.isValid()) {
        console.error('Got invalid time from backend:', v);
    }
    return result;
}

function newProxyObject() {
    return {
        // moment.js checks if the object is empty when cloning with
        // moment(); we make sure it's not
        __nonEmptyForMomentJs__: true,
    };
}

function decodeValue(decoder, valueToDecode) {
    var decodedValue = decoder(valueToDecode);
    if (decodedValue === undefined) {
        console.error(
            'decoder passed to decodeProxy failed on value:',
            valueToDecode
        );
        throw new Error('decode failed');
    }
    return decodedValue;
}

function overrideMethods(decoder, valueToDecode) {
    let decodedValue;
    return {
        get: function (target, prop, receiver) {
            if (prop === '__nonEmptyForMomentJs__') {
                return Reflect.get(target, prop, receiver);
            }
            if (decodedValue === undefined) {
                decodedValue = decodeValue(decoder, valueToDecode);
            }
            return Reflect.get(decodedValue, prop, receiver);
        },
    };
}

function decodeProxy(decoder) {
    return function (valueToDecode) {
        return new Proxy(
            newProxyObject(),
            overrideMethods(decoder, valueToDecode)
        );
    };
}

async function getTypePolicies(schemaUri) {
    const schema = await getSchema(schemaUri);
    const typePolicies = {};
    for (const type of schema.types) {
        if (type.kind !== 'OBJECT') continue;
        typePolicies[type.name] = {
            fields: getFieldReaders(type.fields),
            queryType: type.name === schema.queryType.name,
            mutationType: type.name === schema.mutationType.name,
        };
    }
    return typePolicies;
}

function getFieldReaders(fields) {
    const readers = {};
    for (const field of fields) {
        const convert = getConverter(field.type);
        if (!convert) {
            continue;
        }
        readers[field.name] = v => {
            if (v == null) return v;
            return convert(v);
        };
    }
    return readers;
}

function getConverter(type) {
    if (type.kind === 'NON_NULL') {
        return getConverter(type.ofType);
    }
    if (type.kind !== 'SCALAR') {
        return null;
    }
    if (['DateTime', 'Date'].includes(type.name)) {
        return readDateTime;
    }
    if (type.name === 'TimeDelta') {
        return readTimeDelta;
    }
    if (type.name === 'Time') {
        return readTime;
    }
    if (type.name === 'Period') {
        return p => new Period(p.year, p.period);
    }
    if (type.name === 'File') {
        return async dataUri => await dataUriToBlob(dataUri);
    }
    return null;
}

async function getSchema(schemaUri) {
    const response = await fetch(schemaUri, { credentials: 'same-origin' });
    if (!response.ok) {
        throw new Error('Could not retrieve GraphQL schema, got:', response);
    }
    return (await response.json()).__schema;
}
