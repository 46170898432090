import { store } from './store/store.js';
import {
    SessionExpiredError,
    unauthorizedRedirect,
} from './unauthorizedRedirect';
import { language } from './i18n';

async function checkFrontendVersion(r) {
    store.commit(
        'frontendVersion/update',
        r.headers.get('X-Cockpit-Frontend-Version')
    );
    return r;
}

async function checkResponseStatus200(r, employee) {
    if (r.status === 401) {
        unauthorizedRedirect(employee);
        throw new SessionExpiredError('Your session expired');
    } else if (r.status !== 200) {
        let message;
        try {
            message = (await r.json()).error_message;
        } catch (e) {
            message = `An unexpected error occurred: ${r.status} ${r.statusText}`;
        }
        throw new Error(message);
    } else {
        return r;
    }
}

export function getApi({
    url,
    parameters = {},
    decoder = r => r,
    employee = false,
    json = true,
}) {
    var encodedParams = Object.entries(parameters)
        .map(p => p.map(encodeURIComponent).join('='))
        .join('&');

    return apiCall({
        method: 'GET',
        url: url + '?' + encodedParams,
        decoder,
        employee,
        json,
    });
}

export function apiCall({
    method,
    url,
    parameters = {},
    encoder = r => r,
    employee = false,
    json = false,
    decoder = r => r,
}) {
    return Promise.resolve(encoder(parameters))
        .then(encodedParameters =>
            fetch(process.env.VUE_APP_API_URL + url, {
                method,
                body:
                    method === 'GET'
                        ? undefined
                        : JSON.stringify(encodedParameters),
                credentials: 'same-origin',
                headers: {
                    'Accept-Language': language.value,
                },
            })
        )
        .then(r => checkResponseStatus200(r, employee))
        .then(r => checkFrontendVersion(r))
        .then(r => (json ? r.json() : r))
        .then(decoder)
        .catch(e => {
            store.commit('errorHandling/addMessage', e.message);
            throw e;
        });
}
